.add-car {
    .add-car-panel {
        padding: 30px 20px;
        background-color: $white;
        border-radius: 5px;

        .add-car-img {
            img {
                @media #{$vptabletP} {
                    width: 100%;
                }
            }
        }

        .other-info {
            margin-top: 40px;

            .clock {
                span {
                    font-size: 12px;
                    padding-left: 10px;
                    color: $black;

                    a {
                        color: $carousel-btn-bg;
                        text-transform: uppercase;

                        &:hover {
                            color: $royal-blue;
                        }

                    }

                }
            }
        }


        .car-description {
            display: flex;
            flex-wrap: wrap;

            .car-details {
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin: 10px 0 20px 0;

                @media #{$vpsmmobile} {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                .detail-title {
                    h4 {
                        text-transform: uppercase;
                        font-weight: 700;

                        @media #{$vpmobile} {
                            font-size: 15px;
                        }
                    }
                }

                .detail-description {
                    p {
                        margin-bottom: 0;

                        @media #{$vpmobile} {
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        .custom-btn {
            @media #{$vpsmmobile} {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
            }

            .btn {
                @media #{$vpsmmobile} {
                    margin-bottom: 20px;
                }

                &:last-child {
                    margin-left: 50px;

                    @media #{$vpsmmobile} {
                        margin-left: 0;
                        margin-bottom: 20px;
                    }
                }
            }
        }

    }

    .custom-btn {
        text-align: right;

        svg {
            vertical-align: top;
        }
    }
}