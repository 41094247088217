.inseratoptionen-section {
    .inseratoptionen-box {
        background-color: $white;
        padding: 32px 20px;

        .radio-select {
            flex-direction: column;

            .tags {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
            }

            &.sty-1 {
                margin: 0;
            }
        }
    }
}

.check-box-select {
    .custom-box {
        display: flex;
        align-items: center;

        span {
            &.check-text {
                margin-left: 10px;
            }
        }

        .as-checkbox {
            border-radius: 50%;
        }
    }
}