.bg-image {
    background-image: url(../img/mix/about-mid.jpg);
    width: auto;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;

    .section-content {
        .section-info {
            @media #{$vpmobile} {
                margin-bottom: 30px;
            }

            h4 {
                color: $white;
                font-size: 20px;
                font-weight: 700;
                text-align: center;

                @media #{$vptabletP} {
                    font-size: 17px;
                }
            }

            .numbers {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 25px;

                h4 {
                    font-size: 57px;
                    font-weight: 800;
                    margin-left: 10px;

                    @media #{$vptabletP} {
                        font-size: 40px;
                    }
                }
            }
        }

    }
}

.about-banner {
    .postBox {
        padding: 70px 0 70px 0;
        margin-bottom: 0;

        .postImg {
            margin-bottom: 0;

            .post-overlay {
                .tosvg {
                    width: 108px;
                    height: 108px;
                }
            }
        }
    }

    @media #{$vptabletP} {
        .postBox {
            padding: 35px 0 35px 0;

            .postImg {
                .post-overlay {
                    .tosvg {
                        width: 78px;
                        height: 78px;
                    }
                }
            }
        }
    }

    @media #{$vpsmmobile} {
        .postBox {
            padding: 30px 0;

            .postImg {
                .post-overlay {
                    .tosvg {
                        width: 58px;
                        height: 58px;
                    }
                }
            }
        }
    }
}