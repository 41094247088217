.services {
    textarea.form-control {
        height: 214px;
    }

    .c-form {
        .custom-btn {
            @media #{$vptabletP} {
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }

    .postBox {
        &.ht-50 {
            height: 50%;
            overflow: hidden;

            @media(max-width:767px) {
                height: auto;
                overflow: visible;
            }
        }
    }
}

.service-marktplatz-points {
    ul {
        list-style: disc;
        margin-left: 20px;

        li {
            margin-bottom: 10px;
        }
    }
}