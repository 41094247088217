.fahzreugdaten {
    .vehicle-type {
        margin-top: 40px;

        &::after {
            display: none;
        }
    }

    .fahzreugdaten-section {
        margin-top: 30px;

        .form-group {
            h5 {
                margin-bottom: 10px;

                span {
                    font-weight: 300;
                    font-size: 17px;
                }
            }

            .garantie {
                .garantie-radio {
                    display: flex;
                    margin-top: 13px;
                    align-items: center;

                    span {
                        &.radio-text {
                            margin-left: 10px;
                        }
                    }
                }
            }

            &.customization {
                margin-bottom: 0;
            }

            .custom-margin {
                @media #{$vpmobile} {
                    margin-bottom: 20px;
                }
            }

        }

        .mar-top {
            margin-top: 45px;

            @media #{$vpmobile} {
                margin-top: 0;
            }
        }

        .custom-box {
            display: flex;

            .custom-checkbox {
                flex-shrink: 0;
            }

            span {
                &.check-text {
                    margin-left: 10px;
                    font-size: 12px;
                }

                &.technical-specifications-check {
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
        }

    }

    .c-form.sty-1 .form-group .form-control {
        background-color: $white;
    }

    .fileuploder {
        .showname {
            display: block;
            margin-bottom: 10px;

            i {
                font-style: normal;
                display: block;
                margin-bottom: 5px;
            }
        }
    }
}

textarea {
    resize: auto;
    width: 100%;
    border: none;
    height: 125px;

    &.ht-15 {
        height: 150px;
    }
}