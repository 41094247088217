.car-overview-dropdown {
    border-bottom: 1px solid $bdColor1;

    &.border-none {
        border-bottom: none;
    }

    p {
        line-height: 50px;
    }
}

.vehicle-type {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        border-bottom: 1px solid $bdColor1;
        width: 100%;
        bottom: 0;
        left: 0;
    }

}

.section-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 40px;
    flex-wrap: wrap;

    @media #{$vpmobile} {
        flex-direction: column;
        justify-content: center;
    }

    .heading {
        margin: auto 0;

        @media #{$vpmobile} {
            margin: auto;
        }

        p {
            color: $royal-blue;
            font-weight: 700;
            margin: auto;

            span {
                color: $carousel-btn-bg;
            }
        }
    }

    .postDes {
        .custom-btn {
            .btn {
                text-transform: none;
                padding: 5px 20px;

                .tosvg {
                    margin-left: 10px;
                }

            }

            span {
                vertical-align: middle;
            }
        }

        @media #{$vpmobile} {
            margin: 20px auto 0;
        }
    }

    .select2-box {
        &.custom-field {
            width: 255px;

            @media #{$vpmobile} {
                margin: 20px auto;
            }
        }

        .select2-container--default {
            .select2-selection--single {
                border: none;
                height: 40px;
                padding: 4px;
                background-color: $cGray;

            }

            .select2-selection__rendered {
                line-height: 30px;
            }

            .select2-selection__placeholder {
                color: $black;
                font-size: 18px;
            }

        }
    }
}

.filter-section {
    .custom-btn {
        a {
            svg {
                margin-right: 10px;

                &.tosvg {
                    width: 18px;
                    height: 20px;
                }
            }

            &:hover {

                svg {
                    path {
                        fill: $cSecondary;
                    }
                }

                span {
                    color: $cSecondary;
                }

            }
        }
    }

    .blank-background {
        span {
            vertical-align: middle;
        }
    }

    .fahzreugdaten-section {
        margin-top: 35px;

        .c-form.sty-1 .form-group .form-control {
            background-color: #ffffff;
        }
    }

}

.car-filteration {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media(max-width:991px) {
        flex-direction: column;
    }
}

.filter-car {
    height: 305px;
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    flex: 0 0 504px;
    max-width: 504px;
    margin-right: 30px;

    .slick-list,
    .slick-track {
        height: 100%;
    }

    .slick-slide {
        >div {
            height: 100%;
        }

        .car-slide {
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    @media(max-width:1199px) {
        flex: 0 0 400px;
        max-width: 400px;
    }

    @media(max-width:991px) {
        flex: 0 0 100%;
        max-width: 100%;
        border-radius: 5px 5px 0 0;
    }

    @media(max-width:575px) {
        height: 250px;
    }
}