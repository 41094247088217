.car-detail {
    .car-detail-heading {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media #{$vpsmmobile} {
            justify-content: center;
        }

        .renault {
            p {
                color: $carousel-btn-bg;
                margin-bottom: 0;

                strong {
                    font-size: 36px;
                    text-transform: uppercase;
                    color: $black;

                    @media #{$vptabletP} {
                        font-size: 27px;
                    }

                    @media #{$vpmobile} {
                        font-size: 20px;
                    }

                    &::after {
                        bottom: -8px;
                        width: 50px;
                    }
                }
            }
        }

        .cost {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                color: $light-grey2;
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 0;
                line-height: 1.3;

                @media #{$vpmobile} {
                    font-size: 12px;
                }

                span {
                    font-size: 26px;
                    color: $carousel-btn-bg;

                    @media #{$vptabletP} {
                        font-size: 22px;
                    }

                    @media #{$vpmobile} {
                        font-size: 18px;
                    }
                }

                &.tax {
                    text-align: right;
                }
            }
        }
    }

    .detail-options {
        display: flex;

        @media #{$vptabletP} {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .option-box {
            background-color: $white;
            width: 170px;
            padding: 10px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            margin-right: 50px;

            @media #{$vptabletP} {
                flex: 0 0 45%;
                margin-top: 30px;
                max-width: 45%;
                margin-right: 0;
            }

            @media #{$vpmobile} {
                flex: 0 0 48%;
                max-width: 48%;
            }

            @media (max-width:400px) {
                flex: 0 0 100%;
                margin-top: 30px;
                max-width: 100%;
                margin-right: 0;
            }

            a {
                font-size: 13px;
                color: $black;
                display: flex;
                align-items: center;

                &:hover {
                    color: $carousel-btn-bg;
                }

                svg {
                    margin-right: 10px;

                    &.tosvg {
                        &.pop-icons {
                            width: 15px;
                            height: 15px;

                            @media (max-width:400px) {
                                width: 30px;
                                height: 30px;
                            }
                        }

                        &.message {
                            width: 18px;
                            height: 18px;
                        }

                        &.phone {
                            width: 20px;
                            height: 20px;
                        }

                        &.location {
                            width: 18px;
                            height: 25px;
                            flex-shrink: 0;

                        }
                    }


                    &.contact {
                        margin-right: 15px;
                    }

                    &.location-icon {
                        margin-right: 24px;

                    }

                    @media (max-width:400px) {
                        margin: 10px 0;
                    }
                }

                @media (max-width:400px) {
                    flex-direction: column;
                }
            }
        }

        &.contact-details {
            margin: 40px 30px;

            .option-box {
                width: 200px;

                &.location {
                    width: 290px;
                    background-color: transparent;

                    @media #{$vptabletP} {
                        margin: 30px auto 0;
                    }

                    @media (max-width:575px) {
                        max-width: 100%;
                        width: 100%;
                        flex: 0 0 100%;
                        margin: 30px 0 0 0;
                    }

                    @media (max-width:400px) {
                        text-align: center;
                    }
                }

                a {
                    font-size: 16px;
                }
            }

        }
    }

    .detail-social {
        background-color: $white;
        margin-top: 20px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media #{$vpmobile} {
            flex-direction: column;
        }

        .add-to-compair {
            display: flex;

            @media #{$vpmobile} {
                justify-content: center;
            }

            a {
                display: flex;

                .add-txt {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                    font-size: 14px;
                    margin-left: 10px;
                    color: $black;
                }

                &:hover {
                    .add-txt {
                        color: $carousel-btn-bg;
                    }

                    .circle-btn {
                        background-color: $cSecondary;
                    }
                }
            }
        }

        .social-icons {
            @media #{$vpmobile} {
                justify-content: center;
                display: flex;
                margin-top: 30px;
            }

            ul {
                display: flex;

                li {
                    margin-right: 15px;
                    display: flex;
                    align-items: center;
                    transition: all .35s ease;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:hover {
                        .tosvg {
                            path {
                                fill: $cSecondary;
                            }
                        }
                    }

                    svg {
                        &.tosvg {
                            &.social {
                                width: 30px;
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .car-detail-img {
        margin-top: 60px;

        .feature-images {
            @media #{$vptabletP} {
                display: flex;
                justify-content: space-between;
                margin-top: 50px;
            }

            .side-img {
                padding-bottom: 70px;

                @media #{$vptabletL} {
                    padding-bottom: 60px;
                }

                &:last-child {
                    padding-bottom: 0;
                }

                @media #{$vptabletP} {
                    padding-bottom: 0;
                    margin: 0 5px;
                }
            }
        }
    }

    .car-detail-carDescription {
        display: flex;
        flex-wrap: wrap;

        .options {
            flex: 0 0 25%;
            max-width: 25%;
            flex-wrap: wrap;
            margin: 40px 0 15px 0;

            @media #{$vptabletP} {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (max-width:425px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 20px 0 15px;
            }

            .option-title {
                p {
                    font-size: 24px;
                    margin-bottom: 15px;

                    @media #{$vpmobile} {
                        font-size: 18px;
                    }

                    @media (max-width:425px) {
                        text-align: center;
                    }
                }
            }

            .option-img {
                display: flex;

                @media (max-width:425px) {
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                }


                h6 {
                    font-size: 18px;
                    margin-bottom: 0;
                    margin-left: 10px;

                    @media #{$vpmobile} {
                        font-size: 14px;
                    }

                    @media (max-width: 425px) {
                        margin-left: 0;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .feature-chart {
        display: flex;
        flex-wrap: wrap;
        margin: 30px 0;

        .spac-title {
            flex: 0 0 25%;
            max-width: 25%;

            @media #{$vptabletP} {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media #{$vpsmmobile} {
                text-align: center;
            }

            p {
                font-size: 22px;

                @media #{$vptabletP} {
                    font-size: 18px;
                }

                @media #{$vpsmmobile} {
                    font-size: 16px;
                }
            }

            &.spac-type {
                p {
                    font-weight: 700;
                }
            }
        }

        &.energy-data {
            margin-top: 0;
        }
    }

    .energy-data-heading {
        margin-top: 30px;

        h4 {
            font-size: 22px;
            font-weight: 700;

            @media #{$vpsmmobile} {
                font-size: 18px;
                margin-bottom: 20px;
                text-align: center;
            }
        }
    }

    .tosvg {
        &.calender {
            width: 32px;
            height: 25px;
        }

        &.road {
            width: 51px;
            height: 24px;
        }

        &.speed {
            width: 10px;
            height: 24px;
        }

        &.handschaltung {
            width: 27px;
            height: 27px;
        }

        &.diesel {
            width: 21px;
            height: 23px;
        }

        &.anthrazit {
            width: 22px;
            height: 22px;
        }

        &.acids {
            width: 35px;
            height: 22px;
        }
    }
}