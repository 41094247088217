.logo {
    width: 184px;
    height: 184px;
    background-color: $white;
    border-radius: 50%;
    padding: 11px;
    position: absolute;
    top: -30px;
    left: -11px;

    a {
        display: block;

        img {
            width: 100%;
            height: 100%;
        }
    }

    @media (max-width:1199px) {
        width: 120px;
        height: 120px;
        top: 0;
    }

    @media #{$vptabletP} {
        position: static;
    }

    @media #{$vpsmmobile} {
        width: 86px;
        height: 100px;
        padding: 7px 0;
    }
}

.header {
    position: relative;
    z-index: 4;

    .header-top {
        background-color: $cCharcoal;
        padding: 13px 0;

        .flex {
            justify-content: flex-end;
            align-items: center;
        }

        .popup-btn {
            display: none;
            margin-left: 35px;
            margin-top: 12px;

            @media #{$vptabletP} {
                display: block;
            }

            .tosvg {
                path {
                    fill: $white;
                }
            }
        }

        .language-selector {
            margin-left: 24px;
            margin-top: 15px;
            display: none;

            @media #{$vptabletP} {
                display: block;
            }

            .select2-container--default .select2-selection--single {
                border: none;
                background-color: transparent;
                height: auto;
            }

            .select2-container--default .select2-selection--single .select2-selection__placeholder {
                color: $white;
            }

            .select2-container--default .select2-selection--single .select2-selection__rendered {
                color: $white;
                font-size: 14px;
                line-height: 1;
            }

            .select2-container--default .select2-selection--single .select2-selection__arrow b {
                transform: translate(-50%, -50%);
            }

            .select2-container--default .select2-selection--single .select2-selection__arrow b:after {
                background-image: url(../img/icons/arrow.png);
                margin-left: 0px;
            }
        }

        .icon-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .tosvg {
                width: 15px;
                height: 16px;
                margin-right: 10px;
                flex-shrink: 0;

                path {
                    fill: $white;
                }

                &.ic-clock {
                    width: 18px;
                    height: 18px;
                }
            }

            .ic-text {
                color: $white;
                font-size: 14px;
                font-weight: 400;
                vertical-align: middle;
            }

            a {
                display: block;
                color: $white;

                &:hover {
                    text-decoration: underline !important;
                }
            }

            &.phone-link {
                .ic-text {
                    font-size: 12px;
                    font-weight: 300;

                    @media (max-width:400px) {
                        font-size: 11px;
                    }
                }
            }

            &.login-link {
                margin-left: 50px;
            }
        }

        .spacer-line {
            &:before {
                content: "";
                margin-right: 25px;
                margin-left: 25px;
                width: 2px;
                height: 22px;
                background-color: white;
            }
        }

        &.phase1 {
            @media (max-width:991px) {
                .social-links {
                    &.spacer-line {
                        flex: 0 0 100%;
                        justify-content: center;
                    }
                }
            }

            @media (max-width:767px) {
                .icon-btn {
                    &.ic-address {
                        flex: 0 0 100%;
                        justify-content: center;
                    }
                }

                .social-links {
                    &.spacer-line {
                        flex: 0 0 auto;
                        justify-content: flex-start;
                    }
                }
            }
        }

        @media (max-width:1199px) {
            .flex {
                justify-content: center;
            }
        }

        @media (max-width:991px) {
            .flex {
                flex-wrap: wrap;
            }

            .social-links {
                &.spacer-line {
                    margin-top: 10px;

                    &:before {
                        display: none;
                    }
                }
            }

            .icon-btn {
                &.login-link {
                    margin-top: 12px;
                }
            }

            .mb-row {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        @media (max-width:767px) {
            .social-links {
                &.spacer-line {
                    margin-top: 10px;

                    &:before {
                        display: block;
                    }
                }
            }

            .icon-btn {
                &.phone-link {
                    &.spacer-line {
                        margin-top: 10px;

                        &:before {
                            display: none;
                        }
                    }
                }

                &.login-link {
                    margin-left: 0;
                }
            }

            .mb-row {
                width: 100%;
            }
        }

        @media (max-width:575px) {
            .icon-btn {
                .ic-text {
                    font-size: 12px;
                }

                &.ic-address {
                    width: 100%;
                    justify-content: center;
                }
            }
        }

        @media (max-width:480px) {
            // .spacer-line {
            //     &:before {
            //         margin-right: 8px;
            //         margin-left: 8px;
            //     }
            // }

            .icon-btn {
                &.login-link {
                    margin-left: 15px;

                    .tosvg {
                        margin-right: 5px;
                    }
                }
            }

            // .social-links {
            //     a {
            //         margin-left: 8px;

            //         &:first-child {
            //             margin-left: 0;
            //         }
            //     }
            // }
        }

        @media (max-width:400px) {
            .icon-btn {
                .ic-text {
                    font-size: 11px;
                }
            }
        }
    }

    .header-nav {
        height: 120px;
        position: relative;
        padding-left: 184px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width:1199px) {
            padding-left: 120px;
        }

        @media (max-width:991px) {
            padding-left: 0;
            justify-content: space-between;
            height: auto;
        }
    }

    .navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .menuclose {
            display: none;
        }

        >ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            >li {
                margin-left: 42px;
                position: relative;

                a {
                    font-size: 18px;
                    font-weight: 400;
                    color: $cCharcoal;

                    &:hover,
                    .is--active {
                        color: $cPrimary;
                    }
                }

                &.dropdown {
                    >a {
                        position: relative;

                        &:after {
                            content: "";
                            border-bottom: 0;
                            margin-left: 10px;
                            display: inline-block;
                            vertical-align: middle;
                            border-top: 6px solid $cCharcoal;
                            border-right: 6px solid transparent;
                            border-left: 6px solid transparent;
                        }

                        &:hover {
                            &:after {
                                border-top-color: $cPrimary;
                            }
                        }
                    }

                    &:hover {
                        >a {
                            color: $cPrimary;

                            &:after {
                                border-top-color: $cPrimary;
                            }
                        }

                        .sub-menu {
                            @include showdd;
                        }
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &.is--active>a {
                    color: $cPrimary;
                }

                .sub-menu {
                    min-width: 145px;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    background-color: $white;
                    padding: 14px 0;
                    @include hidedd;
                    z-index: 1;

                    li {
                        margin-bottom: 12px;
                        padding: 0 10px;
                        position: relative;
                        z-index: 1;

                        a {
                            display: block;
                        }

                        &:first-child {
                            border-top: 1px solid $bdColor;
                            padding-top: 12px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 50%;
                        box-shadow: 0 2px 2px 0 rgba($black, .05);
                    }
                }

                &.language-selector {
                    margin-left: 24px;

                    @media #{$vptabletP} {
                        display: none;
                    }

                    @media #{$vptabletL} {
                        margin-left: 6px;
                    }

                    .select2-container--default .select2-selection--single {
                        border: none;
                        height: auto;

                        .select2-selection__rendered {
                            line-height: 1;
                        }
                    }

                    .select2-container--default .select2-selection--single .select2-selection__placeholder {
                        color: $black;
                    }

                }
            }

            @media (max-width:1199px) {
                >li {
                    a {
                        font-size: 16px;
                    }
                }
            }
        }

        >.btn {
            margin-left: 24px;
        }

        .search-dd {
            margin-left: 24px;

            .search-btn {
                cursor: pointer;

                .tosvg {
                    width: 16px;
                    height: 16px;

                    path {
                        fill: $cCharcoal;
                    }
                }
            }

            &:hover {
                .search-btn {
                    .tosvg {
                        path {
                            fill: $cPrimary;
                        }
                    }
                }
            }

            @media (max-width:1199px) {}
        }

        .popup-btn {
            display: block;
            margin-left: 24px;

            .tosvg {
                width: 20px;
                height: 16px;
            }

            @media #{$vptabletP} {
                display: none;
            }
        }

        @media (max-width:991px) {
            position: fixed;
            left: -100%;
            top: 0;
            width: 280px;
            background-color: $white;
            height: 100%;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 50px 0 30px 0;
            z-index: 4;
            transition: left .35s ease-in-out;
            overflow-y: auto;
            box-shadow: 2px 0 2px 0 rgba($black, .05);

            .menuclose {
                display: block;
                position: absolute;
                right: 15px;
                top: 15px;
                width: 15px;
                height: 15px;
                cursor: pointer;

                .tosvg {
                    width: 100%;
                    height: 100%;
                    vertical-align: top;

                    path {
                        fill: $cPrimary;
                    }
                }

                &:hover {
                    .tosvg {
                        path {
                            fill: $cSecondary;
                        }
                    }
                }
            }

            >ul {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                margin-bottom: 30px;

                >li {
                    margin-left: 0;
                    width: 100%;
                    padding: 10px 15px;

                    >a {
                        display: block;
                    }

                    .sub-menu {
                        position: static;
                        width: 100%;
                        display: none;
                    }

                    &.dropdown {
                        &:hover {
                            .sub-menu {
                                display: block;
                            }
                        }
                    }
                }
            }

            >.btn {
                margin-left: 15px;
                width: calc(100% - 30px);
            }

            .search-dd {
                margin-left: 15px;
                width: calc(100% - 30px);

                .search-btn {
                    display: none;
                }

                .dropdown {
                    @include showdd;
                    position: static;
                    padding: 0;
                    margin-bottom: 30px;
                    width: 100%;

                    .head-search {
                        border-top: 0;
                        padding: 0;
                        width: 100%;

                        .btn {
                            top: 0;
                            right: 0;
                        }
                    }

                    &:after {
                        display: none;
                    }
                }
            }

            &.is--open {
                left: 0;
            }
        }

        @media (max-width:575px) {
            width: 260px;
        }
    }
}

/* Mobile Menu Start */
.nav-hamburger {
    width: 35px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -15px;
    cursor: pointer;
    transform: rotate(0deg);
    display: none;

    @media #{$vptabletP} {
        display: block;
    }

    span {
        display: block;
        position: absolute;
        height: 4px;
        width: 50%;
        background: $cPrimary;
        opacity: 1;
        transform: rotate(0deg);
        transition: .25s ease-in-out;

        &:nth-child(even) {
            left: 50%;
            border-radius: 0 9px 9px 0;
        }

        &:nth-child(odd) {
            left: 0px;
            border-radius: 9px 0 0 9px;
        }

        &:nth-child(1),
        &:nth-child(2) {
            top: 0px;
        }

        &:nth-child(3),
        &:nth-child(4) {
            top: 50%;
            margin-top: -2px;
        }

        &:nth-child(5),
        &:nth-child(6) {
            bottom: 0px;
        }
    }

    &.is--active {
        span {

            &:nth-child(1),
            &:nth-child(6) {
                transform: rotate(45deg);
            }

            &:nth-child(2),
            &:nth-child(5) {
                transform: rotate(-45deg);
            }

            &:nth-child(1) {
                top: 9px;
                left: 4px;
            }

            &:nth-child(2) {
                left: calc(50% - 4px);
                top: 9px;
            }

            &:nth-child(3) {
                left: -50%;
                opacity: 0;
            }

            &:nth-child(4) {
                left: 100%;
                opacity: 0;
            }

            &:nth-child(5) {
                left: 4px;
                top: 19px;
            }

            &:nth-child(6) {
                left: calc(50% - 4px);
                top: 19px;
            }
        }
    }
}

.dropdown-box {
    position: relative;

    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 15px 0 14px;
        @include hidedd;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 50%;
            box-shadow: 0 2px 2px 0 rgba($black, .05);
        }
    }

    &:hover {
        .dropdown {
            @include showdd;
        }
    }
}

.banners {
    height: 700px;
    position: relative;

    .banner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, .3)
    }

    .banner-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @media #{$vptabletP} {
            width: 500px;
            max-width: 100%;
        }

        @media (max-width:525px) {
            width: 300px;
        }

        h1 {
            font-size: 50px;
            font-weight: 700;
            color: $white;
            text-align: center;

            @media (max-width:1500px) {
                font-size: 40px;
            }

            @media #{$vptabletL} {
                font-size: 33px;
            }

            @media #{$vptabletP} {
                font-size: 30px;
            }

            @media #{$vpmobile} {
                font-size: 25px;
            }

            @media (max-width:525px) {
                font-size: 18px;
            }
        }

        p {
            font-size: 24px;
            text-align: center;
            color: $white;

            @media #{$vptabletL} {
                font-size: 22px;
            }

            @media #{$vptabletP} {
                font-size: 20px;
            }

            @media #{$vpmobile} {
                font-size: 17px;
            }

            @media (max-width:525px) {
                font-size: 14px;
                line-height: normal;
            }
        }

        .mt-40 {
            @media (max-width:525px) {
                margin-top: 10px;
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media(max-width:767px) {
        height: 350px;
    }

    @media(max-width:525px) {
        height: 220px;
    }

    @media(max-width:360px) {
        height: 200px;
    }
}

.language-selector {
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: -5px;
    }
}