.bilder-hochladen {
    p {
        span {
            font-weight: 700;
        }
    }

    .photo-section {
        display: flex;
        margin: 40px 0;
        flex-wrap: wrap;

        @media #{$vpsmmobile} {
            flex-direction: column;
            justify-content: center;
        }

        .main-photo {
            @media #{$vpsmmobile} {
                display: flex;
                justify-content: center;
            }
        }

        .photo-text {
            margin: 15px 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .mainPhoto-text {
                @media #{$vpsmmobile} {
                    text-align: center;
                }
            }

            .link-text {
                display: flex;

                @media #{$vpsmmobile} {
                    justify-content: center;
                }

                ul {
                    display: flex;

                    li {
                        color: $cSecondary;
                        vertical-align: middle;
                        font-size: 18px;
                        font-weight: 700;
                        margin-left: 5px;

                        @media #{$vpmobile} {
                            font-size: 14px;
                        }

                        &:first-child {
                            margin-left: 0;
                        }

                        &::after {
                            content: "|";
                        }

                        &:last-child {
                            &::after {
                                display: none;
                            }
                        }

                        a {
                            color: $cSecondary;

                            &:hover {
                                color: $carousel-btn-bg;
                            }
                        }


                    }
                }

            }
        }

        .check-box-select {
            &.sty-1 {
                margin-left: auto;
                margin-right: 150px;
                margin-top: 15px;

                @media #{$vptabletP} {
                    margin-right: 0;
                }

                @media #{$vpsmmobile} {
                    margin: auto;
                }

                .check-text {
                    @media #{$vpsmmobile} {
                        margin-left: 5px;
                    }

                }
            }
        }
    }
}