.radio-select {
    display: flex;
    margin: 15px 0 35px 0;
}

.form-check {
    &.sty-1 {
        padding-left: 0;
    }

    .label-radio {
        display: flex;
        align-items: center;

        span {
            &.radio-text {
                margin-left: 10px;
            }
        }
    }
}