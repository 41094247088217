.coming-soon-page {
    .header {
        padding-bottom: 120px;

        .header-top {
            .flex {
                justify-content: space-between;
            }

            @media (max-width:1199px) {
                .flex {
                    justify-content: space-between;
                }
            }
        }

        @media #{$vpsmmobile} {
            padding-bottom: 65px;
        }

        @media (max-width:575px) {
            .icon-btn {
                &:first-child {
                    width: auto;
                    justify-content: flex-start;
                }
            }
        }
    }

    .logo {
        right: 0;
        left: 0;
        top: 18px;
        margin: auto;

        @media (max-width:1199px) {
            width: 184px;
            height: 184px;
        }

        @media #{$vptabletP} {
            position: absolute;
        }

        @media #{$vpsmmobile} {
            width: 100px;
            height: 100px;
            top: 33px;
        }
    }

    .heading-1 {
        max-width: 700px;
        margin: auto;

        @media #{$vpmobile} {
            max-width: 95%;
        }
    }

    .inner-container {
        .img-mg {
            margin: 50px 0;
        }

        .para {
            font-size: 18px;
            color: $cCharcoal;
            line-height: 28px;
            font-weight: 400;
            max-width: 700px;
            margin: 0 auto;
        }

        @media #{$vpmobile} {
            .para {
                max-width: 95%;
            }
        }

        @media #{$vpsmmobile} {
            .para {
                font-size: 16px;
                line-height: normal;
            }
        }
    }

    .subscribe-form {
        max-width: 540px;
        margin: 50px auto 0;

        @media #{$vpmobile} {
            max-width: 95%;
        }
    }

    .footer {
        .footer-widget {
            text-align: center;

            .flex {
                justify-content: center;
                align-items: center;
            }
        }
    }
}