.kasse-bestatigen {
    .custom-table {
        @media #{$vpsmmobile} {
            width: 150%;
        }

        .table-striped {
            tbody {
                tr {
                    background-color: transparent;

                    &:first-child {
                        background-color: $white;
                        color: $carousel-btn-bg;
                    }

                    &.border-bottom {
                        border-bottom: 1px solid $light-grey2;
                    }
                }

                td {
                    background-color: transparent;
                    border: none;
                    padding-top: 0px;
                    padding-bottom: 10px !important;

                    &.pt-custom1 {
                        padding-top: 10px;
                    }

                    &.pt-custom {
                        padding-top: 25px;
                    }

                    &:first-child {
                        width: 50%;
                    }

                }

            }

        }
    }

}