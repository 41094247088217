html,
body {
    font-family: $primaryFont !important;
    font-weight: normal;
    color: $black;
    font-weight: 300;
    line-height: 1.4;
}

h2 {
    font-size: $font-size-30;
    font-weight: bold;
    line-height: 40px;
}

h3 {
    font-size: 29px;
    font-weight: 700;
    margin-bottom: 25px;

    &.bigger {
        font-size: 36px;
        text-transform: uppercase;
    }

    @media #{$vptabletP} {
        font-size: 26px;
    }

    @media #{$vpmobile} {
        font-size: 22px;
        margin-bottom: 15px;
    }
}

h4 {
    font-size: $font-size-22;
    font-weight: 600;
    margin-bottom: 0;

    &.sty-1 {
        font-size: 26px;
        font-weight: 700;
    }

    &.underline {
        border-bottom: 1px solid $light-grey2;
        padding-bottom: 10px;

    }

    @media #{$vptabletP} {
        font-size: 20px;
    }

    @media #{$vpmobile} {
        font-size: 18px;
    }

}

h5 {
    font-size: $font-size-18;
    font-weight: 700;
    margin-bottom: 0;

    @media #{$vptabletP} {
        font-size: 15px;
    }

    @media #{$vpmobile} {
        font-size: 13px;
    }
}

p,
strong {
    font-size: $font-size-18;
    line-height: 28px;
    font-weight: 400;
    margin: 0 0 25px 0;

    i {
        font-style: italic;
    }

    @media #{$vptabletL} {
        font-size: 16px;
    }

    @media #{$vpmobile} {
        font-size: 16px;
    }

    @media (max-width:575px) {
        font-size: 14px;
    }
}

strong {
    font-weight: 700;
}

.heading-1 {
    text-align: center;

    h2 {
        font-size: 36px;
        color: $black;
        line-height: 1.1;
        font-weight: 700;
        margin: 0 0 20px 0;
        padding-bottom: 24px;
        position: relative;
        text-transform: uppercase;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            height: 10px;
            width: 123px;
            background: url('../img/icons/ic-heading1.svg') no-repeat center center scroll;
        }
    }

    p {
        font-size: $font-size-22;
        color: $cCharcoal;
        line-height: 28px;
        font-weight: 600;
        margin: 0;
    }

    @media #{$vpmobile} {
        h2 {
            font-size: 26px;
        }

        p {
            font-size: $font-size-18;
            line-height: normal;

            &.bold {
                font-weight: 700;
            }
        }
    }

    &.no-after {
        h2 {
            &:after {
                display: none;
            }
        }
    }
}

.ul-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .li-item {
        display: flex;
        flex-direction: column;
        position: relative;
        font-size: $font-size-18;
        line-height: 28px;
        font-weight: 400;
        padding-left: 40px;
        margin-bottom: 20px;

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 18px;
            height: 13px;
            background-image: url(../img/icons/ic-tic-red.svg);
            background-size: 15px;
            background-repeat: no-repeat;
            background-position: center;
        }

        @media #{$vpmobile} {
            font-size: 16px;
            line-height: 22px;

            &:before {
                top: 10px;
            }
        }

        @media (max-width:575px) {
            font-size: 14px;
        }
    }

    strong {
        margin: 0 20px 0 0;
    }
}

.text-underline {
    position: relative;
    display: inline-block;

    &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 30px;
        height: 3px;
        border-radius: 24%;
        background-color: $royal-blue;
    }
}