/* ====================================
Popup CSS
==================================== */

.c-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;

    .popup {
        display: none;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1170px;
        padding: 20px;
        max-height: 100%;
        width: 100%;
        opacity: 0;
        top: 40%;
        transition: all .3s 0s;
        overflow-y: auto;

        .popup-wrap {
            background: $white;
            padding: 20px;
            border-radius: 2px;
        }

        .popup-action {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 999;
            margin-right: 20px;

            &.sty-1 {
                right: 10px;
                top: 10px;

                .c-close {
                    border: 0;
                    width: 25px;
                    height: 25px;

                    &:before,
                    &:after {
                        background: $cGray;
                    }

                    &:before {
                        top: 11px;
                        left: 3px;
                        width: 19px;
                        height: 3px;
                        border-radius: 10px;
                    }

                    &:after {
                        top: 3px;
                        left: 11px;
                        height: 19px;
                        width: 3px;
                        border-radius: 10px;
                    }
                }

                &:hover {
                    .c-close {
                        background: transparent;
                    }
                }
            }
        }

        &.popup--full-screen {
            top: 0;
            left: 0;
            transform: none;
            width: 100%;
            height: 100%;
            padding: 0;
            max-width: none;

            .popup-wrap {
                padding: 0;
                height: 100%;

                .plyr {
                    height: 100%;
                }
            }

            &.active {
                top: 0;
            }
        }

        &.active {
            top: 50%;
            opacity: 1;
            transition-delay: .3s;
        }
    }

    .overlay {
        display: block;
        height: 100%;
        width: 100%;
        background: transparent;
        transition: all .3s;
    }

    .popup-action {
        text-align: center;
        margin-top: 20px;
    }

    .js-video {
        height: 100%;
    }

    &.popup--open {
        .overlay {
            background: rgba($black, .5);
        }
    }
}

/* ====================================
END - Popup CSS
==================================== */

/* ====================================
Popup Close BUtton
==================================== */

.c-close {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid $cPrimary;
    border-radius: 999px;
    position: relative;
    transform: rotate(45deg);
    background: $cPrimary;
    transition: all .3s;
    box-sizing: border-box;

    span {
        display: none;
    }

    &:before,
    &:after {
        content: '';
        box-sizing: border-box;
        display: block;
        position: absolute;
        top: 11px;
        left: 15%;
        height: 1px;
        width: 70%;
        background: #0d4f8a;
        transition: all .3s;
    }

    &:after {
        top: 15%;
        left: 11px;
        height: 70%;
        width: 1px;
    }

    &:hover {
        background: #0d4f8a;
        transform: rotate(135deg);

        &:before,
        &:after {
            background: #fff;
        }
    }

    &:active {
        transform: rotate(225deg);
    }

    @media (max-width: 767px) {
        .c-close {
            width: 20px;
            height: 20px;

            &:after {
                left: 9px;
            }

            &:before {
                top: 9px;
            }
        }
    }
}

.plyr__video-wrapper {
    height: 100%;
    padding: 0 !important;
}


// popups
.c-popup {
    .popup {
        &.sty-1 {
            padding: 0;
            max-width: 100%;

            .popup-wrap {
                padding: 0;
                background-color: transparent;
            }

            .popupHead {
                position: relative;
                min-height: 30px;
                border-radius: 12px 12px 0 0;
                background-color: $cPrimary;

                .popup-action {
                    right: 24px;
                    top: 27px;
                    margin: 0;
                }

                .text-underline {
                    color: $white;
                    margin: 27px 24px;

                    &::after {
                        background-color: $white;
                    }
                }
            }

            .popupContent {
                border-radius: 0 0 12px 12px;
                background-color: $white;
                padding: 35px 30px 60px 30px;

                &.sty-1 {
                    padding: 0 10px;
                }

                .comparision-table {
                    table {
                        &.sty-1 {
                            margin-bottom: 0;

                            @media #{$vptabletP} {
                                width: 125%;
                            }

                            tr {
                                border-top: 1px solid $light-grey;

                                &:nth-child(1) {
                                    border-top: none;
                                }

                                &:nth-child(3) {
                                    border-top: none;
                                }

                                &:nth-child(4) {
                                    border-top: none;
                                }

                                &:nth-child(5) {
                                    border-top: none;
                                }

                                td {
                                    border-top: none;
                                    text-align: center;
                                    font-size: 18px;

                                    del {
                                        font-size: 16px;
                                        font-weight: 800;
                                        padding-right: 10px;

                                        @media #{$vptabletP} {
                                            font-size: 13px;
                                        }
                                    }

                                    span {
                                        color: $carousel-btn-bg;
                                        font-weight: 800;
                                    }

                                    @media #{$vptabletP} {
                                        font-size: 14px;
                                    }

                                    &:nth-child(1) {
                                        text-align: right;
                                    }

                                    .c-close {
                                        width: 18px;
                                        height: 18px;

                                        &::before {
                                            top: 8px;
                                            left: 19%;
                                            height: 1px;
                                            width: 70%;
                                            background: $white;
                                        }

                                        &::after {
                                            top: 15%;
                                            left: 8px;
                                            height: 70%;
                                            width: 1px;
                                            background: $white;
                                        }
                                    }

                                    &.img-sec {
                                        width: 25%;

                                    }
                                }
                            }

                        }
                    }

                    .table-striped {
                        tbody {
                            tr:nth-of-type(odd) {
                                background-color: transparent;
                            }

                            td:nth-of-type(even) {
                                background-color: $cGray;
                            }
                        }

                    }
                }
            }

            .private-customer {
                display: none;
            }

            .dealer-cont {
                padding-top: 15px;
            }

            &.privateCustomerPopup {
                .private-customer {
                    display: block;

                    &.text-underline {
                        display: inline-block;
                    }
                }

                .dealer-cont {
                    display: none;
                    padding-top: 15px;
                }
            }
        }

        .custom-btn {
            @media #{$vpmobile} {
                text-align: center;
            }

            .btn-large {
                @media #{$vpsmmobile} {
                    width: 100%;
                }
            }
        }
    }

    .c-form {
        &.sty-1 {
            .form-group {
                p {
                    margin-bottom: 5px;
                }

                .form-control {

                    &.comment {
                        height: 80px;
                    }

                    &[readonly] {
                        cursor: inherit;
                    }
                }

                .form-check {
                    margin-bottom: 10px;

                    &.sty-1 {
                        padding-left: 0;
                    }

                    &.pad-no {
                        @media #{$vpmobile} {
                            padding-left: 0;
                        }
                    }
                }

                .registeraion-radio {
                    display: flex;

                    .form-check {
                        margin-right: 28px;
                    }
                }

                &.registration {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.container-small {
    width: 540px;
    max-width: 100%;
    margin: 0 auto;
}

.less-pad1 {
    padding-right: 5px;

    @media #{$vpmobile} {
        padding-right: 15px;
    }
}

.less-pad2 {
    padding-left: 5px;

    @media #{$vpmobile} {
        padding-left: 15px;
    }
}

.subscribe-form {
    margin: 35px 0;
    padding-left: 40px;

    &.style-1 {
        background-color: $cGray;

        .tosvg {
            position: absolute;
            top: 15px;
            left: 20px;
        }

        .email-field {
            background-color: $cGray;
        }
    }
}