.stripe {
    .terms {
        margin: 30px 0 0 0;

        ul {
            list-style: disc;
            margin-left: 17px;
            margin-bottom: 25px;

            li {
                color: $black;
                font-size: 18px;
                margin-bottom: 7px;

                @media #{$vptabletL} {
                    font-size: 16px;
                }

                @media #{$vpsmmobile} {
                    font-size: 14px;
                }
            }
        }
    }
}