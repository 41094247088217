.sec-team {
    padding-top: 75px;

    @media(max-width:991px) {
        padding-top: 50px;
    }

    @media(max-width:767px) {
        padding-top: 30px;
    }

    .teamBox {
        margin-bottom: 75px;

        @media #{$vpmobile} {
            margin-bottom: 50px;
        }

        .postImg {
            height: 285px;

            @media(max-width:1199px) {
                height: auto;
            }
        }

        .postDes {
            padding: 0 30px;

            h3 {
                margin-bottom: 0;
            }

            .cutomerDes {
                margin-bottom: 0;
                font-weight: 700;
                text-align: center;
                color: rgba($black, 0.5);
            }

            @media(max-width:1199px) {
                padding: 0;
            }
        }

        .social-links {
            padding-top: 35px;
            justify-content: center;

            @media #{$vpmobile} {
                padding-top: 20px;
            }

            a {
                .tosvg {
                    path {
                        fill: $cPrimary;
                    }
                }

                &:hover {
                    .tosvg {
                        path {
                            fill: $cCharcoal;
                        }
                    }
                }
            }
        }
    }
}

.sec-team-detail {
    .postBox {
        .postDes {
            h3 {
                text-align: left;
                margin-bottom: 0px;
                text-transform: uppercase;
                font-size: 36px;

                @media #{$vptabletP} {
                    font-size: 26px;
                }

                @media #{$vpmobile} {
                    font-size: 22px;
                }
            }

            h4 {
                margin-bottom: 25px;

                @media(max-width:991px) {
                    text-align: left;
                }
            }

            p {
                text-align: left;
                margin-bottom: 25px;
            }

            .post-social-title {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-bottom: 5px;

                .social-links {
                    a {
                        .tosvg {
                            path {
                                fill: $cPrimary;
                            }
                        }

                        &:hover {
                            .tosvg {
                                path {
                                    fill: $cCharcoal;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.sty-2 {
            .postImg {
                width: 350px;
                height: 400px;
                max-width: 100%;
                margin-right: 30px;
                border-radius: 12px;
                flex-shrink: 0;
                margin-bottom: 0;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    object-fit: cover;
                }

                @media(max-width:991px) {
                    height: 300px;
                    width: 250px;
                }

                @media(max-width:767px) {
                    height: auto;
                    width: 100%;
                    margin: 0 auto 30px;
                }
            }

            @media(max-width:991px) {
                flex-direction: row;
            }

            @media(max-width:767px) {
                flex-direction: column;
            }
        }
    }
}