.login {
    .section-login {
        padding: 20px 0;
        display: flex;
        background-color: $white;
        border-radius: 12px;
        border: 1px solid rgba(112, 112, 112, .4);

        @media #{$vpmobile} {
            flex-direction: column;
        }

        .login-form {
            padding: 15px 30px;
            width: 50%;
            border-right: 1px solid $dark-grey;

            @media #{$vpmobile} {
                width: 100%;
                border-right: none;

            }

            &.sty-1 {
                border-right: none;
            }

            p {
                margin: 20px 0;
            }

            .form-group {
                p {
                    margin-bottom: 10px;
                }
            }

            .login-buttons {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .custom-btn {
                    @media #{$vptabletL} {
                        width: 100%;
                    }
                }

            }

            .blank-space {
                height: 192px;
            }

            .btn-large {
                @media #{$vptabletL} {
                    width: 100%;
                }

                &.register {
                    width: 300px;
                    max-width: 100%;
                    background-color: $cGray;
                    border-color: $cGray;
                    color: $black;
                    font-weight: 500;
                    transition: all 0.35s ease;
                    font-weight: 700;

                    &.sty-1 {
                        width: 230px;
                        font-weight: 400;
                        font-size: 22px;
                        padding: 10px 0;
                        text-transform: none;

                        @media #{$vptabletL} {
                            width: 100%;
                            font-size: 16px;
                            padding: 9px 0;
                        }
                    }

                    &:hover {
                        background-color: $cSecondary;
                        border-color: $cSecondary;
                        color: $white;
                    }

                    @media #{$vptabletP} {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.form-group {
    .form-control {
        &.sty-1 {
            background-color: $cGray;
        }
    }
}