.half-bg {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        height: 58%;
        background-color: $cGray;
        width: 100%;
        bottom: 0;
        z-index: 0;
    }

    .container {
        position: relative;
        z-index: 1;
    }

    .news-box {
        margin-top: 40px;
    }
}

.slider-sec {
    position: relative;

    .slide {
        padding: 0 15px;
    }
}

.news {
    margin-bottom: 70px;

    .sliderArrows {
        button {
            top: 15%;
        }
    }

    @media(max-width:1199px) {
        &.slider-sec {
            padding: 0 15px;
        }

        .postBox {
            max-width: 255px;
            margin: 0px auto;
        }
    }
}