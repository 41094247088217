.button-group {
    font-size: 0;
}

.button {
    &:focus {
        outline: none;
    }

    &.tabButton {
        background-color: transparent;
        border: none;
        padding: 0;
        outline: none;
        -webkit-appearance: none;

        p {
            font-weight: normal;

            &.caseStudy {
                @media #{$vpmobile} {
                    font-size: 19px;
                }
            }

            &:after {
                content: "/";
                padding: 0 10px;

            }
        }

        &:last-child {
            p {
                &:after {
                    display: none;

                }
            }
        }
    }
}

.portfolio--page {
    .portfolio--icons {
        ul {
            display: block;
            font-size: 0;

            li {

                width: 16.666667%;
                padding: 50px 5px;
                display: inline-block;
                position: relative;
                text-align: center;

                @media #{$vpmobile} {
                    width: 50%;
                    padding: 0 50px;
                }

                .partners-logos {
                    margin: 0;
                    position: relative;
                    padding: 0;
                    padding-top: 80%;
                    cursor: pointer;
                    border: 1px solid transparent;
                    transition: all 0.3s ease;
                    visibility: visible;

                    img {
                        display: block;
                        margin: 0;
                        position: absolute;
                        width: auto;
                        height: auto;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;
                        opacity: 1;
                        transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85) 0.2s;
                    }

                    .partners-logo--custom {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;

                        .logoTitle {
                            font-size: 18px;

                            font-weight: bold;
                            position: absolute;
                            top: calc(50% - 8px);
                            left: 50%;
                            transform: translate(-50%, 20%);
                            will-change: opacity, transform;
                            opacity: 0;
                            transition: all 0.25s ease 0s;
                        }

                        .logoCategory {
                            font-size: 14px;

                            position: absolute;
                            top: calc(50% + 12px);
                            left: 50%;
                            transform: translate(-50%, 10%);
                            will-change: opacity, transform;
                            opacity: 0;
                            transition: all 0.25s ease 0s;
                            margin-top: 8px;
                            line-height: 1.2;
                        }
                    }

                    &:hover {
                        img {
                            opacity: 0;
                            transition: all 0.25s ease 0s;
                        }

                        .partners-logo--custom {
                            .logoTitle {
                                transform: translate(-50%, -50%);
                                opacity: 1;
                                transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85) 0.2s;
                            }

                            .logoCategory {
                                transform: translate(-50%, -50%);
                                opacity: 1;
                                transition: all 1s cubic-bezier(0.37, 0.31, 0.2, 0.85) 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}