.testimonial-carousel {
    .sliderArrows {
        height: 42px;
        position: relative;
        margin-top: 25px;

        button {
            top: 0;
            transform: none;

            &.slick-prev {
                left: auto;
                right: 62px;
            }

            &.slick-next {
                left: auto;
                right: 0;
            }
        }
    }
    .postBox{
        &.sty-2 {
            .postImg{
                height: 210px;
            }
        }
    }
}