html {
    background-color: $white;
    min-height: 100vh;
    direction: ltr;
    width: 100%;

    &.browserIos {
        overflow-x: hidden;
    }
}

body {
    min-height: 100vh;
    width: 100%;
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

::-moz-selection {
    background: rgba($cCharcoal, .1);
    text-shadow: none;
}

::selection {
    background: rgba($cCharcoal, .1);
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

*,
*:after,
*:before {
    outline: none;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none !important;
    outline: none !important;
    color: $cPrimary;
    cursor: pointer;

    &:hover {
        color: $black;
    }
}

svg {
    transform: translateZ(0);
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
    transition: all .35s ease;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
}

.clearfix:after {
    clear: both;
}

.o-hidden {
    overflow: hidden;
    height: 100%;
}

.u-hide {
    display: none !important;
}

.dnone {
    display: none !important;
}

.u-pos-s {
    position: static;
}

.u-pos-r {
    position: relative;
}

.pl {
    &-no {
        padding-left: 0px;
    }

    &-20 {
        padding-left: 20px;
    }

    &-25 {
        padding-left: 25px;
    }

    &-40 {
        padding-left: 40px;
    }

    &-50 {
        padding-left: 50px;
    }
}

.pr {
    &-20 {
        padding-right: 20px;
    }

    &-25 {
        padding-right: 25px;
    }

    &-40 {
        padding-right: 40px;
    }

    &-50 {
        padding-right: 50px;
    }
}

.pt {
    &-25 {
        padding-top: 25px;
    }

    &-40 {
        padding-top: 40px;
    }

    &-50 {
        padding-top: 50px;
    }

    @media#{$vptabletP} {
        &-lg {
            &-20 {
                padding-top: 20px;
            }

            &-25 {
                padding-top: 25px;
            }

            &-40 {
                padding-top: 40px;
            }

            &-50 {
                padding-top: 50px;
            }
        }
    }

    @media(max-width:767px) {
        &-md {
            &-20 {
                padding-top: 20px;
            }

            &-25 {
                padding-top: 25px;
            }

            &-40 {
                padding-top: 40px;
            }

            &-50 {
                padding-top: 50px;
            }
        }
    }
}

.pb {
    &-20 {
        padding-bottom: 20px;
    }

    &-25 {
        padding-bottom: 25px;
    }

    &-40 {
        padding-bottom: 40px;
    }

    &-50 {
        padding-bottom: 50px;
    }

    &-60 {
        padding-bottom: 60px;
    }

    @media#{$vptabletP} {
        &-lg {
            &-20 {
                padding-bottom: 20px;
            }

            &-25 {
                padding-bottom: 25px;
            }

            &-40 {
                padding-bottom: 40px;
            }

            &-50 {
                padding-bottom: 50px;
            }
        }
    }

    @media(max-width:767px) {
        &-md {
            &-20 {
                padding-bottom: 20px;
            }

            &-25 {
                padding-bottom: 25px;
            }

            &-40 {
                padding-bottom: 40px;
            }

            &-50 {
                padding-bottom: 50px;
            }
        }
    }
}

.ml {
    &-20 {
        margin-left: 20px;
    }

    &-25 {
        margin-left: 25px;
    }

    &-40 {
        margin-left: 40px;
    }

    &-50 {
        margin-left: 50px;
    }
}

.mr {
    &-20 {
        margin-right: 20px;
    }

    &-25 {
        margin-right: 25px;
    }

    &-40 {
        margin-right: 40px;
    }

    &-50 {
        margin-right: 50px;
    }
}

.mt {
    &-10 {
        margin-top: 10px;
    }

    &-20 {
        margin-top: 20px;
    }

    &-25 {
        margin-top: 25px;
    }

    &-30 {
        margin-top: 30px;
    }

    &-40 {
        margin-top: 40px;
    }

    &-50 {
        margin-top: 50px;
    }

    &-85 {
        margin-top: 85px;
    }

    @media#{$vptabletP} {
        &-50 {
            margin-top: 40px;
        }

        &-85 {
            margin-top: 65px;
        }

        &-lg {
            &-20 {
                margin-top: 20px;
            }

            &-25 {
                margin-top: 25px;
            }

            &-40 {
                margin-top: 40px;
            }

            &-50 {
                margin-top: 50px;
            }
        }
    }

    @media(max-width:767px) {
        &-85 {
            margin-top: 40px;
        }

        &-md {
            &-20 {
                margin-top: 20px;
            }

            &-25 {
                margin-top: 25px;
            }

            &-40 {
                margin-top: 40px;
            }

            &-50 {
                margin-top: 50px;
            }
        }
    }

    @media(max-width:767px) {
        &-50 {
            margin-top: 25px;
        }

        &-85 {
            margin-top: 25px;
        }
    }
}

.mb {
    &-no {
        margin-bottom: 0 !important;
    }

    &-10 {
        margin-bottom: 10px !important;
    }

    &-20 {
        margin-bottom: 20px;
    }

    &-25 {
        margin-bottom: 25px;
    }

    &-30 {
        margin-bottom: 30px;
    }

    &-35 {
        margin-bottom: 35px;
    }

    &-40 {
        margin-bottom: 40px;
    }

    &-50 {
        margin-bottom: 50px;
    }

    &-70 {
        margin-bottom: 70px;
    }

    &-85 {
        margin-bottom: 85px;
    }

    @media#{$vptabletP} {
        &-50 {
            margin-bottom: 40px;
        }

        &-70 {
            margin-bottom: 50px;
        }

        &-lg {
            &-20 {
                margin-bottom: 20px;
            }

            &-25 {
                margin-bottom: 25px;
            }

            &-40 {
                margin-bottom: 40px;
            }

            &-50 {
                margin-bottom: 50px;
            }
        }
    }

    @media(max-width:767px) {
        &-70 {
            margin-bottom: 40px;
        }

        &-md {
            &-20 {
                margin-bottom: 20px !important;
            }

            &-25 {
                margin-bottom: 25px;
            }

            &-30 {
                margin-bottom: 30px !important;
            }

            &-40 {
                margin-bottom: 40px;
            }

            &-50 {
                margin-bottom: 50px;
            }
        }
    }
}

.toSvg {
    max-width: 100%;
    max-height: 100%;

    polygon,
    path {
        transition: fill 0.35s linear;
    }
}

input::-ms-clear {
    display: none;
}

.primaryfont {
    font-family: $primaryFont;
}

.for--mobilehide {
    @media #{$vpmobile} {
        display: none !important;
    }
}

.for--mobileshow {
    @media(min-width: 768px) {
        display: none !important;
    }
}

.for--tablethide {
    @media #{$vptabletP} {
        display: none !important;
    }
}

.for--tabletshow {
    @media(min-width: 992px) {
        display: none !important;
    }
}

.grayScale {
    >img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.6;
        transition: all 0.5s ease;
    }

    &:hover {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }

    @media #{$vptabletP} {
        >img {
            -webkit-filter: none;
            filter: none;
            opacity: 1;
        }
    }
}

.nopdlr--mobile {
    @media #{$vpmobile} {
        padding-left: 0;
        padding-right: 0;
    }
}

[class^="box-"] {
    display: none;

    &.showfirst {
        display: block;
    }
}

.gridView {
    >ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 -15px;
        flex-wrap: wrap;

        >li {
            padding: 15px;
            flex: 0 0 25%;

            .programmeExperience {
                width: 100%;
            }

            .IE & {
                flex: 0 0 23%;
            }
        }
    }

    @media (max-width:1550px) {
        >ul {
            >li {
                flex: 0 0 33.33%;
            }
        }
    }

    @media (max-width:1275px) {
        >ul {
            >li {
                flex: 0 0 50%;
            }
        }
    }

    @media #{$vptabletP} {
        >ul {
            >li {
                flex: 0 0 50%;
            }
        }
    }

    @media #{$vpmobile} {
        >ul {
            >li {
                flex: 0 0 100%;
            }
        }
    }
}

.IE {
    .gridView {
        >ul {
            >li {
                flex: 0 0 23%;
            }
        }
    }
}

.popup-is-active {
    overflow: hidden;
}

.loadingDv {
    text-align: center;
    padding: 50px 0 25px 0;
    position: relative;
    min-height: 134px;

    span {
        font-size: 9px;
        font-weight: 300;
        text-transform: uppercase;
        color: $black;
        margin-top: 7px;
        display: block;
    }

    >span {
        cursor: pointer;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 106px;
        transition: all 0.5s ease;
        height: 30px;
    }

    .loadmorebtn {
        display: none;

        >img {
            width: 49px;
        }
    }
}

.row {
    &.pd_10 {
        margin-left: -10px;
        margin-right: -10px;

        [class*=col-] {
            padding: 0 10px;
        }
    }

    &.pd_0 {
        margin-left: 0px;
        margin-right: 0px;

        [class*=col-] {
            padding: 0;
        }
    }
}

.mouseanimateDv {
    display: inline-block;
    text-align: center;
    padding: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 1.7s ease-in-out;
    opacity: 0;
    z-index: 1;
    visibility: hidden;

    .toSvg {
        width: 28px;
        height: 47px;
        display: inline-block;
        transition: all 1s ease-in-out;
        margin-bottom: 13px;
        position: absolute;
        left: 50%;
        top: -100%;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);

        .anim-path1,
        .anim-path2,
        .anim-path3,
        .anim-path4 {
            animation: mouseanimate 1s infinite;
        }

        .anim-path1,
        .anim-path3 {
            animation-delay: .1s;
            animation-direction: alternate;
        }

        .anim-path2,
        .anim-path4 {
            opacity: 0;
            animation-delay: .3s;
            animation-direction: alternate;
        }

        &.touchIcon {
            display: none;
        }

        @media #{$vptabletP} {
            &.touchIcon {
                display: inline-block;
            }

            &.mouseIcon {
                display: none;
            }
        }
    }

    .blurFont {
        opacity: 0;
        transition: all 1s ease-in-out;
        visibility: hidden;
    }

    span {
        padding-top: 11px;
        display: block;
        line-height: 9px;
        text-transform: uppercase;
        color: $white;
        font-weight: 300;
        font-size: 12px;
        transition: all 1s ease-in-out;
        position: absolute;
        opacity: 0;
        visibility: hidden;

        &.slideMRight {
            top: calc(50% + 47px);
            transform: translate(50%, -50%);
        }

        &.slideMLeft {
            top: calc(50% + 67px);
            transform: translate(-50%, -50%);
        }
    }

    .bgImg {
        opacity: 0;
        transition: all 1.5s ease-in-out;
        visibility: hidden;
    }

    .slideMTop {
        top: -100%;
        opacity: 0;
        visibility: hidden;
    }

    .slideMLeft {
        left: -100%;
    }

    .slideMRight {
        right: -100%;
    }

    &.showMAnimate {
        opacity: 1;
        visibility: visible;

        .bgImg {
            opacity: 1;
            visibility: visible;
        }

        .slideMTop {
            top: 50%;
            opacity: 1;
            visibility: visible;
        }

        .blurFont {
            opacity: 0.04;
            visibility: visible;

            .IE & {
                opacity: 0.02;
            }
        }

        .slideMLeft {
            left: 50%;
            opacity: 1;
            visibility: visible;
        }

        .slideMRight {
            right: 50%;
            opacity: 1;
            visibility: visible;
        }
    }
}

@-webkit-keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes mouseanimate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}

.plyr--video {
    .plyr__controls {
        .plyr__control {

            &.plyr__tab-focus,
            &:hover,
            &[aria-expanded=true] {
                background: $cPrimary;
            }
        }
    }
}

.plyr__control--overlaid {
    background: $cPrimary;

    &:hover,
    &:focus {
        background: $cPrimary;
    }
}

.css-scrollable {
    .mCSB_scrollTools {
        .mCSB_draggerRail {
            background-color: rgba($black, .2);
        }

        .mCSB_dragger {
            .mCSB_dragger_bar {
                background-color: rgba(60, 63, 64, .4);
            }
        }
    }
}

.stripe {
    padding-top: 100px;
    padding-bottom: 100px;

    @media #{$vptabletP} {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    @media #{$vpmobile} {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.stripe1 {
    padding-top: 100px;
    padding-bottom: 70px;

    @media #{$vptabletP} {
        padding-top: 75px;
        padding-bottom: 45px;
    }

    @media #{$vpmobile} {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

.stripe2 {
    padding-bottom: 100px;

    @media #{$vptabletP} {
        padding-bottom: 45px;
    }
}

.stripe3 {
    padding-top: 50px;
    padding-bottom: 100px;

    @media #{$vptabletP} {
        padding-top: 75px;
        padding-bottom: 45px;
    }

    @media #{$vpmobile} {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

.stripe4 {
    padding-top: 100px;
    padding-bottom: 0;

    @media #{$vptabletP} {
        padding-top: 75px;
    }

    @media #{$vpmobile} {
        padding-top: 50px;
    }
}

.stripe-sty1 {
    padding-top: 30px;
    padding-bottom: 40px;
}

.flex {
    display: flex;
}

.bgc_cGray {
    background-color: $cGray;
}

.inner-container {
    width: 890px;
    max-width: 100%;
    margin: auto;
}

.social-links {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
        display: block;
        margin-left: 20px;

        &.ic-twitter {
            .tosvg {
                width: 16px;
                height: 16px;
            }

            &:hover {
                .tosvg {
                    path {
                        fill: #00acee;
                    }
                }
            }
        }

        &.ic-facebook {
            .tosvg {
                width: 8px;
                height: 16px;
            }

            &:hover {
                .tosvg {
                    path {
                        fill: #3b5998;
                    }
                }
            }
        }

        &.ic-youtube {
            .tosvg {
                width: 14px;
                height: 16px;
            }

            &:hover {
                .tosvg {
                    path {
                        fill: #c4302b;
                    }
                }
            }
        }

        &.ic-instagram {
            .tosvg {
                width: 14px;
                height: 16px;
            }

            &:hover {
                .tosvg {
                    path {
                        fill: #3f729b;
                    }
                }
            }
        }

        &.ic-phone {
            .tosvg {
                width: 14px;
                height: 14px;
            }

            &:hover {
                .tosvg {
                    path {
                        fill: #3f729b;
                    }
                }
            }
        }

        &.ic-email {
            .tosvg {
                width: 14px;
                height: 14px;
            }

            &:hover {
                .tosvg {
                    path {
                        fill: #3f729b;
                    }
                }
            }
        }

        .tosvg {
            path {
                fill: $white;
                transition: all .35s ease;
            }
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.defaultOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    transform: translateX(100%);
    transition: all .35s ease;
    width: 100%;
    z-index: 3;
    opacity: 0;

    .is--menu & {
        transform: translateX(0);
        opacity: 1;
    }
}

.loaderscreen {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: $white;

    .triple-spinner {
        display: block;
        position: absolute;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 4px solid transparent;
        border-top: 4px solid $cPrimary;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        left: 50%;
        top: 50%;
        margin: -60px 0 0 -60px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            border-radius: 50%;
            border: 4px solid transparent;
            transform-origin: center;
        }

        &:before {
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-top-color: rgba($cSecondary, .85);
            -webkit-animation: spin 3s linear infinite;
            animation: spin 3.5s linear infinite;
        }

        &:after {
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-top-color: rgba($cPrimary, .75);
            -webkit-animation: spin 1.5s linear infinite;
            animation: spin 1.75s linear infinite;
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.lg-scroll {
    @media #{$vptabletP} {
        overflow-x: auto;
    }
}

.mb-scroll {
    @media #{$vpmobile} {
        overflow-x: auto;
    }
}

.txt-style-hd {
    text-align: left;

    p {
        text-align: left !important;
    }

    @media(max-width:991px) {
        text-align: center;

        p {
            text-align: center !important;
        }
    }
}

.row {
    &.pd-3 {
        margin-right: -3px;
        margin-left: -3px;

        [class*=col-] {
            padding: 0 3px;
        }
    }
}

.slick-dots {
    display: flex;
    justify-content: center;
    bottom: -50px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);

    li {
        button {
            background: none;
            border: none;
            text-indent: 9999px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999;
            margin: 15px 5px;
            opacity: .4;
            padding: 0;
        }

        &.slick-active button {
            background-color: #999;
            opacity: 1;
            outline: none;
        }
    }
}

.filter-car {
    .slick-dots {
        display: flex;
        justify-content: center;
        bottom: 10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);

        li {
            button {
                background: none;
                border: none;
                text-indent: 9999px;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #999;
                margin: 15px 5px;
                opacity: .4;
                padding: 0;
            }

            &.slick-active button {
                background-color: #999;
                opacity: 1;
                outline: none;
            }
        }
    }
}


.stepCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    li {
        position: relative;
        flex: 0 0 100px;
        max-width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 100%;
        min-height: 92px;

        .stepTxt {
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            color: $cCharcoal;
            margin-bottom: 10px;
            text-align: center;
        }

        .stepRound {
            border: 6px solid $bdColor1;
            border-radius: 50%;
            background-color: $cGray;
            font-size: 25px;
            font-weight: 400;
            color: $cCharcoal;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            cursor: default;
        }

        &.is--done {
            .stepTxt {
                color: $cPrimary;
            }

            .stepRound {
                background-color: $cGray;
                color: $cCharcoal;
                border-color: $cPrimary;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $cGray;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 23px;
                    background-image: url('../img/icons/ic-tic-red.svg');
                    border-radius: 50%;
                }
            }

            &:before {
                background-color: $cPrimary;
            }
        }

        &.is--active {
            .stepTxt {
                color: $cPrimary;
            }

            .stepRound {
                background-color: $cPrimary;
                color: $white;
                border-color: $cPrimary;
            }

            &:before {
                background-color: $cPrimary;
            }
        }

        &:before {
            content: "";
            height: 6px;
            background-color: $bdColor1;
            left: -100%;
            bottom: 50px;
            margin-bottom: -28px;
            position: absolute;
            right: 50%;
            z-index: 0;
        }

        &:first-child {
            &:before {
                display: none;
            }
        }
    }

    @media(max-width:991px) {
        li {
            .stepRound {
                font-size: 20px;

                &:before {
                    background-size: 20px;
                }
            }

            &:before {
                left: -25%;
            }
        }
    }

    @media(max-width:767px) {
        overflow-x: auto;
        padding-bottom: 20px;
    }
}

.stepbox {
    display: none;

    &.is--active {
        display: block;
    }
}

hr {
    border-top: 2px solid #949494;
}

.slick-slider,
.sliderArrows {
    button {
        &.slick-arrow {
            position: absolute;
            text-indent: -999px;
            background-color: transparent;
            border: none;
            top: 50%;
            transform: translateY(-50%);
            width: 42px;
            height: 42px;
            padding: 0;
            left: -45px;
            overflow: hidden;

            &.slick-prev {
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: 0;
                    top: 0;
                    width: 42px;
                    height: 42px;
                    background-image: url(../img/icons/ic-left.svg);
                    background-size: 42px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
            }

            &.slick-next {
                left: auto;
                right: -45px;

                &:after {
                    content: "";
                    position: absolute;
                    background-image: url(../img/icons/ic-right.svg);
                    background-size: 42px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    left: 0;
                    top: 0;
                    position: absolute;
                    display: block;
                    width: 42px;
                    height: 42px;
                }
            }

            @media(max-width:1199px) {
                left: -25px;

                &.slick-next {
                    right: -25px;
                }
            }

            @media(max-width:767px) {
                left: 0;

                &.slick-next {
                    right: 0;
                }
            }
        }
    }
}

.elementor-9 .elementor-element.elementor-element-0673f95 .jet-smart-listing__heading{
    margin: 0px 0px 40px 0px;
    @media(max-width:767px){
        margin: 20px 0px 20px 0px;
    }
}

.elementor-9 .elementor-element.elementor-element-0673f95 .jet-smart-listing__filter{
    @media(max-width:575px){
        text-align: left;
    }
}

.jet-smart-listing__filter-item:first-child a{
    margin-left: 0;
}

.elementor-9 .elementor-element.elementor-element-7b49043{
    display: none;
}