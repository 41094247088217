.stripe {
    .section-contact {
        padding: 60px 0;
    }

    .form-comment {
        .opening-hours-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
            height: 25px;
            align-items: center;

            h5 {
                font-size: 18px;
                font-weight: 700;
            }

            p {
                margin-bottom: 0;
                font-size: 18px;
                font-weight: 400;
            }

            @media(max-width:1199px) {
                h5 {
                    font-size: 14px;
                }

                p {
                    font-size: 14px;
                }
            }

            @media(max-width:991px) {
                margin-bottom: 15px;
            }
        }
    }
}

.contact-map {
    iframe {
        width: 100%;
        height: 570px;

        @media(max-width:1199px) {
            height: 500px;
        }

        @media(max-width:991px) {
            height: 400px;
        }

        @media(max-width:767px) {
            height: 350px;
        }

        @media(max-width:575px) {
            height: 300px;
        }
    }
}

.contact-detail {
    .contact-detail-img {
        .tosvg {
            &.contact-detail-icon {
                width: 72px;
                height: 72px;
            }
        }
    }
}