button,
.btn {
    &:focus {
        box-shadow: none;
    }
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    padding: 9px 15px;
    border-radius: 8px;
    line-height: 1.4;

    &.btn-primary {
        color: $white;
        background-color: $cPrimary;
        border-color: $cPrimary;

        @media #{$vptabletP} {
            font-size: 16px;
        }

        @media #{$vpmobile} {
            font-size: 14px;
        }

        &:hover {
            background-color: $cSecondary;
            border-color: $cSecondary;
        }

        &.pagination {
            text-transform: initial;
            font-size: 18px;
        }
    }

    &.wd-1 {
        min-width: 220px;
    }

    &.btn-small {
        font-size: 16px;
        font-weight: 400;
        text-transform: inherit;
        border-radius: 4px;
        line-height: 1.3;
        padding: 4px 16px;

        &.sty1 {
            font-size: 18px;
            padding: 10px 5px;
            width: 160px;

            @media #{$vpsmmobile} {
                width: 100%;
                margin: 20px 0px;
            }
        }
    }

    &.grey-button {
        font-size: 22px;
        font-weight: 800;
        color: $carousel-btn-bg;
        background-color: $cGray;
        border-color: $cGray;
        border-radius: 10px;
        line-height: 1.3;
        padding: 12px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, .16);
        transition: all .35s ease;

        @media #{$vpsmmobile} {
            width: 100%;
        }

        &:hover {
            color: $cGray;
            background-color: $carousel-btn-bg;
            border-color: $carousel-btn-bg;
            box-shadow: none;
        }
    }

    &.blank-background {
        background-color: transparent;
        border: none;
        color: $carousel-btn-bg;
        padding: 0;

        &:hover {
            background-color: transparent;
            border: none;
        }

        &:active {
            background-color: transparent !important;
            border-color: transparent !important;
            color: $carousel-btn-bg !important;
        }

        &.sty-1 {
            color: $black;
            font-size: 18px;
            font-weight: 300;
            margin: 20px 0;

            &:hover {
                color: $carousel-btn-bg;
            }
        }
    }


    &.btn-large {
        width: 250px;

        @media #{$vpsmmobile} {
            width: 100%;
        }
    }

    &.btn-x-large {
        width: 320px;

        @media #{$vpsmmobile} {
            width: 100%;
        }
    }
}