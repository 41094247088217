.partners {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media #{$vptabletP} {
        justify-content: center;
    }

    .partner-logo {
        display: flex;
        align-items: center;
    }
}