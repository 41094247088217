// Custom Variables //
$primaryFont: 'Muli', sans-serif;


// Set Colors
$black: #000000;
$white: #ffffff;
$cPrimary: #D9000D;
$cSecondary: #005FAC;
$cCharcoal: #323232;
$cGray: #F5F5F5;
$cGray-sty1: #f2f2f2;
$bdColor: #707070;
$bdColor1: #d0d0d0;
$carousel-btn-bg: #E72A33;
$royal-blue: #0771B8;
$light-grey: #e5e5e5;
$light-grey2: #727272;
$dark-grey: #7f7f7f;



// Media Sizes
$vpdesktopMin: "(min-width:2100px)";
$vpMddesktop: "(max-width:1700px)";
$vpdesktop: "(max-width:1400px)";
$vptabletL: "(max-width:1199px)";
$vptabletP: "(max-width:991px)";
$vpmobile: "(max-width:767px)";
$vpsmmobile: "(max-width:575px)";
$vpxsmobile: "(max-width:374px)";


// fonts Variable
$font-size-14: 14px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-30: 30px;