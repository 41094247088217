.latest-vehicles {
    margin-top: 60px;

    .image-wrap {
        position: relative;

        .carousel-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($black, 0.7);
            height: 100%;
            transition: all .35s ease-in-out;
            opacity: 0;

            @media #{$vptabletP} {
                opacity: 1;
            }

            .carousel-icons-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                opacity: 0;
                transition: opacity .2s ease-in-out;

                a {
                    margin: 0 6px;
                    width: 21px;
                    height: 21px;
                    border: 1px solid $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    &.ic-eye {
                        .tosvg {
                            width: 14px;
                            height: 9px;
                        }
                    }

                    &.ic-compare {
                        .tosvg {
                            width: 11px;
                            height: 12px;
                        }
                    }

                    &.ic-photo {
                        .tosvg {
                            width: 11px;
                            height: 9px;
                        }
                    }

                    &:hover {
                        background-color: $white;

                        .tosvg {
                            path {
                                fill: $carousel-btn-bg;
                            }
                        }
                    }
                }

                @media #{$vptabletP} {
                    opacity: 1;
                }
            }
        }
    }


    .carousel-wrapper {
        max-width: 255px;
        border-radius: 12px;
        overflow: hidden;
        margin: 0 auto;

        &:hover {
            .carousel-overlay {
                opacity: 1;
            }

            .carousel-icons-wrap {
                opacity: 1;
            }
        }

        .carousel-btn-wrapper {
            background-color: $carousel-btn-bg;
            padding: 10px;

            .carousel-btn {
                color: $white;
                font-size: $font-size-20;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }

    .small-carousel-img {
        height: 180px;
        width: 100%;
        border-radius: 12px 12px 0 0;
    }

    .carousel-content-wrap {
        width: 100%;
        display: inline-block;
        background-color: $white;
        padding: 10px 10px 25px;

        .carousel-model-wrap {
            display: flex;
            justify-content: space-evenly;
            margin-bottom: 15px;
            opacity: 0.5;

            .carousel-model-numbers {
                font-size: $font-size-14;
            }
        }

        .carousel-details {
            display: block;
            font-size: $font-size-20;
            font-weight: bold;
            line-height: 1;
            text-transform: uppercase;
        }

        .carousel-sub-details {
            font-size: $font-size-14;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

}

.icon-calendar,
.icon-fuel {
    display: flex;
    justify-content: center;

    &:before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        background-image: url(../img/icons/ic-calendar.svg);
        background-size: 15px;
        margin-right: 5px;
    }
}

.icon-fuel {
    &:before {
        background-image: url(../img/icons/ic-fuel.svg);
    }
}


button:focus {
    outline: none;
    box-shadow: none;
}